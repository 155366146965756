<template>
    <div :class="{ colored: isColoredFooter, white: !isColoredFooter }">
        <div class="footer">
            <div class="footer__navigation">
                <div class="footer__navigation__logo_and_social">
                    <b-nav-item
                        :to="{ path: '/'}"
                        class="footer__navigation-logo"
                    >
                        <img
                            v-if="isColoredFooter"
                            class="footer__navigation-logo-img"
                            src="../assets/images/logo_footer_white.svg"
                            alt="card"
                        >
                        <img
                            v-else
                            class="footer__navigation-logo-img"
                            src="../assets/images/logo_footer_dark.svg"
                            alt="card"
                        >
                    </b-nav-item>
                    <div class="footer__navigation__social">
                        <b-nav-item
                            href="https://twitter.com/OversecuredInc"
                            class="footer__navigation-item"
                        >
                            <img
                                v-if="isColoredFooter"
                                class="navigation-item--twitter"
                                src="../assets/images/x-icon-white.svg"
                                alt="twitter"
                            >
                            <img
                                v-if="!isColoredFooter"
                                class="navigation-item--twitter"
                                src="../assets/images/x-icon-dark.svg"
                                alt="twitter"
                            >
                        </b-nav-item>
                        <b-nav-item
                            href="https://www.linkedin.com/company/oversecured/"
                            class="footer__navigation-item "
                        >
                            <img
                                v-if="isColoredFooter"
                                class="navigation-item--linkedin"
                                src="../assets/images/linkedin-icon-on-black.svg"
                                alt="linkedin"
                            >
                            <img
                                v-if="!isColoredFooter"
                                class="navigation-item--linkedin"
                                src="../assets/images/linkedin-icon-on-white.svg"
                                alt="linkedin"
                            >
                        </b-nav-item>
                    </div>
                </div>
                <div class="footer__navigation__links_and_rights">
                    <div class="rights">
                        <p :class="{ footer__rights: isColoredFooter, footer__rightsBlack: !isColoredFooter }">
                            &copy; 2025
                        </p>
                    </div>
                    <div class="footer__navigation-links">
                        <b-nav-item
                            :to="{ path: '/about'}"
                            class="footer__navigation-item"
                        >
                            About
                        </b-nav-item>
                        <b-nav-item
                            href="https://blog.oversecured.com/"
                            target="_blank"
                            class="footer__navigation-item"
                        >
                            Blog
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/docs/quick-start/'}"
                            class="footer__navigation-item"
                        >
                            Quick Start
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/docs/ci-cd/'}"
                            class="footer__navigation-item"
                        >
                            CI/CD
                        </b-nav-item>
                        <b-nav-item
                            v-show="isUserType"
                            :to="{ path: '/docs/api/'}"
                            class="footer__navigation-item"
                        >
                            API
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/vulnerabilities'}"
                            class="footer__navigation-item"
                        >
                            Vulnerabilities
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/contact-us'}"
                            class="footer__navigation-item"
                        >
                            Contact Us
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/terms'}"
                            class="footer__navigation-item"
                        >
                            Terms of Use
                        </b-nav-item>
                        <b-nav-item
                            :to="{ path: '/privacy'}"
                            class="footer__navigation-item"
                        >
                            Privacy Policy
                        </b-nav-item>
                    </div>
                </div>
            </div>
        </div>
        <MainGDPR />
    </div>
</template>
<script>

import { mapGetters } from 'vuex';
import MainGDPR from './main/MainGDPR.vue';
import Vue from 'vue';

const coloredFooterPages = ['/', '/vulnerabilities', '/terms', '/privacy', '/cookies', '/request-demo', '/partner', '/pricing'];
export default {
    components: {
        MainGDPR,
    },
    data() {
        return {
            isColoredFooter: false,
            cookiesUserType: Vue.$cookies.get('userType'),
        };
    },
    computed: {
        ...mapGetters({
            userType: 'user/userType',
        }),
        isUserType() {
            return this.cookiesUserType !== null || this.userType !== null;
        },
    },
    watch:{
        $route (){
            this.isColoredFooter = this.calcFooterColor();
        },
    },
    beforeMount() {
        this.isColoredFooter = this.calcFooterColor();
    },
    methods:{
        calcFooterColor: () => {
            let path = document.location.pathname;
            return  coloredFooterPages.includes(path);
        },
    },
};
</script>
<style lang="scss" scoped>
@import '../assets/css/fonts';
@import "../assets/css/variables";

.footer {
    padding: 40px 0;

    &__background {
        background-color: #252321;
    }

    &__navigation {
        display: flex;
        flex-direction: column;
        list-style: none;
        row-gap: 40px;

        &__logo_and_social {
            display: flex;
            flex-direction: row;
            align-items: center;
        }

        &__links_and_rights {
            display: flex;
            align-items: baseline;
            justify-content: space-between;
            gap: 40px;
        }

        &__social {
            display: flex;
            flex-direction: row;
            gap: 20px;
            margin-left: auto;
        }
    }

    &__navigation-item {

        &:hover {
            text-decoration: underline;
        }
    }

    &__navigation-links {
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        justify-content: flex-end;
        column-gap: 40px;
        row-gap: 40px;
    }

    &__rights,
    &__rightsBlack {
        @include Aspekta-font(600, 18px, normal, #ffffff);
        letter-spacing: 0.34px;
        white-space: nowrap;
    }

    &__rightsBlack {
        color: #5C6A7A;
    }

    li.nav-item {
        a.nav-link {
            @include Aspekta-font(450, 18px, 150%, #021631);
            letter-spacing: 0.36px;

            .colored & {
                color: white;
            }

            .navigation-item--twitter,
            .navigation-item--linkedin {
                height: 24px;
                width: 24px;
            }
        }
    }
}

.colored, .white {
    display: flex;
    justify-content: center;
}

.colored {
    background-color: #0C0D0E;
}

.white {
    background-color: white;
}

@media (max-width: 1440px) {
    .footer {
        padding: 40px 20px;

        &__navigation-logo-img {
            width: 245px;
            height: 30px;
        }

        &__navigation {

            gap: 40px;

            &__links_and_rights {
                flex-direction: column-reverse;
                gap: 40px;
            }
        }

        &__navigation-links {
            column-gap: 40px;
            row-gap: 20px;
            justify-content: flex-start;
        }

        &__rights,
        &__rightsBlack {
            font-size: 18px;
            // font-weight: 400;
            letter-spacing: 0.54px;
        }

        li.nav-item {
            a.nav-link {
                @include Aspekta-font(400, 14px, 140%, #021631);
                letter-spacing: 0.28px;
            }
        }
    }
}

@media (max-width: 450px) {
    .footer {

        &__navigation-logo-img {
            width: 163px;
            height: 20px;
        }

        &__navigation {

            gap: 20px;

            &__links_and_rights {
                flex-direction: column-reverse;
                gap: 20px;
            }
        }

        &__rights,
        &__rightsBlack {
            font-size: 17px;
            letter-spacing: 0.34px;
        }

        &__navigation-links {
            column-gap: 20px;
        }
    }
}

</style>

