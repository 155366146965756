<template>
    <div class="markdown">
        <div>
            <b-nav-item :to="{ path: `${makeLinkToAnchor('integrations')}`}" class="md__link-wrapper">
                <h2 class="md__item-title">Integrations</h2>
            </b-nav-item>
            <p>Only users with a <code>company</code> account have access to Integrations. Managing
                Integrations, including
                <b-nav-item class="link" :to="{ path: `/integrations/create`}">
                    applying for new Integrations
                </b-nav-item>
                or renewing existing ones, is only possible via the platform. The API
                provides access for working with existing Integrations.
            </p>
            <h3 :id="makeAnchor('Possible Integrations statuses')"><a
                :href="makeSubLinkToAnchor('Possible Integrations statuses', 'integrations')">Possible Integrations
                statuses</a></h3>
            <table class="table">
                <thead>
                <tr>
                    <th>Status</th>
                    <th>Comments</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>UNDER_REVIEW</td>
                    <td>Integration request has been sent and is being examined by the Oversecured
                        team
                    </td>
                </tr>
                <tr>
                    <td>ACTIVE</td>
                    <td>Integration is active: you can work with it and submit new versions to be
                        scanned
                    </td>
                </tr>
                <tr>
                    <td>EXPIRED</td>
                    <td>Integration has expired: you can continue to work with versions that you have
                        already
                        submitted, but you cannot upload new versions to be scanned
                    </td>
                </tr>
                <tr>
                    <td>RENEWAL_REQUESTED</td>
                    <td>Extension request submitted</td>
                </tr>
                <tr>
                    <td>REJECTED</td>
                    <td>Request declined</td>
                </tr>
                </tbody>
            </table>
            <h3 :id="makeAnchor('Getting a list of Integrations')"><a
                :href="makeSubLinkToAnchor('Getting a list of Integrations', 'integrations')">Getting a list of
                Integrations</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>GET /v1/integrations?limit={limit}&amp;page={page}</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>limit</td>
                    <td>integer</td>
                    <td>number of Integrations on one page</td>
                </tr>
                <tr>
                    <td>page</td>
                    <td>integer</td>
                    <td>page number</td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>count</td>
                    <td>integer</td>
                    <td>total number of Integrations</td>
                </tr>
                <tr>
                    <td>rows</td>
                    <td>array</td>
                    <td>array of Integrations on the current page</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations?limit=10&amp;page=1' \
  -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"count"</span><span class="token operator">:</span> <span
                    class="token number">1</span><span class="token punctuation">,</span>
  <span class="token property">"rows"</span><span class="token operator">:</span> <span
                    class="token punctuation">[</span>
    <span class="token punctuation">{</span>
      <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"5e7096e2-7fed-43f0-9290-22d03c64cfbf"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"name"</span><span class="token operator">:</span> <span class="token string">"Oversecured Vulnerable Android App"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"description"</span><span class="token operator">:</span> <span class="token string">"This app includes many known vulnerabilities on Android"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"status"</span><span class="token operator">:</span> <span class="token string">"ACTIVE"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"expires_at"</span><span class="token operator">:</span> <span class="token string">"2021-01-03T15:48:15.946Z"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"app_id"</span><span class="token operator">:</span> <span class="token string">"oversecured.ovaa"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"logo_url"</span><span class="token operator">:</span> <span class="token string">"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/77e31a12-82cc-4b67-b3f0-15b244acb5a2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201205%2Fus-east-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20201205T232500Z&amp;X-Amz-Expires=86400&amp;X-Amz-Signature=c87e268706dd1123c023f46a80e85f7a9b34ec9b2516161edf080867a1b5fe33&amp;X-Amz-SignedHeaders=host"</span><span
                    class="token punctuation">,</span>
      <span class="token property">"smart_filter_enabled"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span><span class="token punctuation">,</span>
      <span class="token property">"notify_when_done"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span><span class="token punctuation">,</span>
      <span class="token property">"notify_when_expired"</span><span class="token operator">:</span> <span
            class="token boolean">true</span><span class="token punctuation">,</span>
      <span class="token property">"default_branch"</span><span class="token operator">:</span> <span class="token string">"main"</span>
    <span class="token punctuation">}</span>
  <span class="token punctuation">]</span>
<span class="token punctuation">}</span>
</code></pre>
            <!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
            <!-- <span class="token punctuation">,</span> -->
      <!-- <span class="token property">"auto_pull_new_versions"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span> -->

            </div>
            <h3 :id="makeAnchor('Getting details on Integrations')"><a
                :href="makeSubLinkToAnchor('Getting details on Integrations', 'integrations')">Getting details on
                Integrations</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>GET /v1/integrations/{integration_id}</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>integration_id</td>
                    <td>string(uuid)</td>
                    <td>Integration ID, generated automatically when the Integration is created</td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <div style="overflow: auto">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>id</td>
                        <td>string(uuid)</td>
                        <td>Integration ID</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td>Integration name</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td>description of the Integration</td>
                    </tr>
                    <tr>
                        <td>status</td>
                        <td>string</td>
                        <td>Integration status</td>
                    </tr>
                    <tr>
                        <td>expires_at</td>
                        <td>string</td>
                        <td>date and time when the Integration will be transferred or was transferred to
                            EXPIRED
                            status
                        </td>
                    </tr>
                    <tr>
                        <td>platform</td>
                        <td>string</td>
                        <td>name of the mobile platform for which the Integration was created</td>
                    </tr>
                    <tr>
                        <td>app_id</td>
                        <td>string</td>
                        <td>app ID, for Android it will be a package value</td>
                    </tr>
                    <tr>
                        <td>logo_url</td>
                        <td>string</td>
                        <td>signed Amazon S3 link to the app logo</td>
                    </tr>
                    <tr>
                        <td>smart_filter_enabled</td>
                        <td>boolean</td>
                        <td>value indicating whether SmartFilter is active</td>
                    </tr>
                    <tr>
                        <td>notify_when_done</td>
                        <td>boolean</td>
                        <td>value indicating whether automated notifications after each new version is
                            scanned
                            are
                            active
                        </td>
                    </tr>
                    <tr>
                        <td>notify_when_expired</td>
                        <td>boolean</td>
                        <td>value indicating whether automated notifications after expired the Integration subscription
                        </td>
                    </tr>
                    <tr>
                      <td>default_branch</td>
                      <td>string</td>
                      <td>Branch name, created by the user. <code>main</code> is the default</td>
                    </tr>
                    <!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
                    <!-- <tr>
                        <td>auto_pull_new_versions</td>
                        <td>boolean</td>
                        <td>value indicating whether automated downloading of each new version from the
                            relevant
                            app
                            store is active
                        </td>
                    </tr> -->
                    </tbody>
                </table>
            </div>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}' \
  -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"5e7096e2-7fed-43f0-9290-22d03c64cfbf"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"name"</span><span class="token operator">:</span> <span class="token string">"Oversecured Vulnerable Android App"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"description"</span><span class="token operator">:</span> <span class="token string">"This app includes many known vulnerabilities on Android"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"status"</span><span class="token operator">:</span> <span
                    class="token string">"ACTIVE"</span><span class="token punctuation">,</span>
  <span class="token property">"expires_at"</span><span class="token operator">:</span> <span class="token string">"2021-01-03T15:48:15.946Z"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"app_id"</span><span class="token operator">:</span> <span class="token string">"oversecured.ovaa"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"logo_url"</span><span class="token operator">:</span> <span class="token string">"https://redacted.s3.amazonaws.com/f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/77e31a12-82cc-4b67-b3f0-15b244acb5a2.png?X-Amz-Algorithm=AWS4-HMAC-SHA256&amp;X-Amz-Credential=AKIAUC5S2Y2XMUGCWT7O%2F20201205%2Fus-east-2%2Fs3%2Faws4_request&amp;X-Amz-Date=20201205T232500Z&amp;X-Amz-Expires=86400&amp;X-Amz-Signature=c87e268706dd1123c023f46a80e85f7a9b34ec9b2516161edf080867a1b5fe33&amp;X-Amz-SignedHeaders=host"</span><span
                    class="token punctuation">,</span>
  <span class="token property">"smart_filter_enabled"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span><span class="token punctuation">,</span>
  <span class="token property">"notify_when_done"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span><span class="token punctuation">,</span>
  <span class="token property">"notify_when_expired"</span><span class="token operator">:</span> <span
                    class="token boolean">true</span><span class="token punctuation">,</span>
  <span class="token property">"default_branch"</span><span class="token operator">:</span> <span class="token string">"main"</span>
<span class="token punctuation">}</span>
</code></pre>
<!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
        <!-- <span class="token punctuation">,</span> -->
  <!-- <span class="token property">"auto_pull_new_versions"</span><span class="token operator">:</span> <span
                    class="token boolean">false</span> -->

            </div>
            <h3 :id="makeAnchor('Changing Integration settings')"><a
                :href="makeSubLinkToAnchor('Changing Integration settings', 'integrations')">Changing Integration
                settings</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>PUT /v1/integrations/{integration_id}/settings</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <div style="overflow: auto">
                <table class="table">
                    <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Comment</th>
                    </tr>
                    </thead>
                    <tbody>
                    <tr>
                        <td>integration_id</td>
                        <td>string(uuid)</td>
                        <td>Integration ID, generated automatically when the Integration is created</td>
                    </tr>
                    <tr>
                        <td>name</td>
                        <td>string</td>
                        <td>new name of the Integration</td>
                    </tr>
                    <tr>
                        <td>description</td>
                        <td>string</td>
                        <td>new description of the Integration</td>
                    </tr>
                    <tr>
                        <td>logo_bucket_key</td>
                        <td>string</td>
                        <td>key received when the picture was uploaded.
                            <b-nav-item
                                :to="{ path: `${makeLinkToAnchor('uploading')}#${makeAnchor('Uploading images')}`}">
                                View details
                            </b-nav-item>
                        </td>
                    </tr>
                    <tr>
                        <td>smart_filter_enabled</td>
                        <td>boolean</td>
                        <td>value indicating whether SmartFilter is active</td>
                    </tr>
                    <tr>
                        <td>notify_when_done</td>
                        <td>boolean</td>
                        <td>value indicating whether automated notifications after each new version is
                            scanned
                            are
                            active
                        </td>
                    </tr>
                    <tr>
                      <td>notify_when_expired</td>
                      <td>boolean</td>
                      <td>value indicating whether automated notifications after expired the Integration subscription
                      </td>
                    </tr>
                    <!-- leave _Automatically pull new versions from Google Play_ feature for the future -->
                    <!-- <tr>
                        <td>auto_pull_new_versions</td>
                        <td>boolean</td>
                        <td>value indicating whether automated downloading of each new version from the
                            relevant
                            app
                            store is active
                        </td>
                    </tr> -->
                    </tbody>
                </table>
            </div>
            <p><strong>Using curl</strong></p>
            <!-- from "-d" cuted "auto_pull_new_versions", leave _Automatically pull new versions from Google Play_ feature for the future -->
            <!-- , "auto_pull_new_versions": true -->
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl https://api.oversecured.com/v1/integrations/{integration_id}/settings \
  -d '{"name": "Oversecured Vulnerable Android App", "description": "This app includes many known vulnerabilities on Android", "logo_bucket_key": "f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/77e31a12-82cc-4b67-b3f0-15b244acb5a2.png", "smart_filter_enabled": true, "notify_when_done": true, "notify_when_expired": true}' \
  -H "Content-Type: application/json" -X PUT \
    -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <h3 :id="makeAnchor('List of versions')"><a :href="makeSubLinkToAnchor('List of versions', 'integrations')">List
                of versions</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>GET /v1/integrations/{integration_id}/branches/{branch_name}/versions?limit={limit}&amp;page={page}</code>
                </li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>integration_id</td>
                    <td>string(uuid)</td>
                    <td>Integration ID, generated automatically when the Integration is created</td>
                </tr>
                <tr>
                    <td>branch_name</td>
                    <td>string</td>
                    <td>Branch name, created by the user. <code>main</code> is the default</td>
                </tr>
                <tr>
                    <td>limit</td>
                    <td>integer</td>
                    <td>number of integrations on one page</td>
                </tr>
                <tr>
                    <td>page</td>
                    <td>integer</td>
                    <td>page number</td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>count</td>
                    <td>integer</td>
                    <td>total number of versions</td>
                </tr>
                <tr>
                    <td>rows</td>
                    <td>array</td>
                    <td>array of versions on the current page
                    </td>
                </tr>
                </tbody>
            </table>
            <p><strong>Version JSON object</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>id</td>
                    <td>string(uuid)</td>
                    <td>Version ID, generated automatically when the new version is uploaded</td>
                </tr>
                <tr>
                    <td>file_name</td>
                    <td>string</td>
                    <td>file name of the app version uploaded to the platform</td>
                </tr>
                <tr>
                    <td>platform</td>
                    <td>string</td>
                    <td>platform of the scanned version</td>
                </tr>
                <tr>
                    <td>version_id</td>
                    <td>string</td>
                    <td>name of the app version</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>string</td>
                    <td>version scan status.
                        <b-nav-item
                            :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Possible scan statuses')}`}">
                            View possible statuses
                        </b-nav-item></td>
                </tr>
                <tr>
                    <td>version_compare</td>
                    <td>object</td>
                    <td>comparative severity statistics as against the previous version (if any),
                        showing
                        how
                        many vulnerabilities have been created or fixed in each severity group
                    </td>
                </tr>
                <tr>
                    <td>completed_at</td>
                    <td>string</td>
                    <td>date and time when the scan was completed (or <code>null</code>)</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions?limit={limit}&amp;page={page}' \
  -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"count"</span><span class="token operator">:</span> <span
                    class="token number">2</span><span class="token punctuation">,</span>
  <span class="token property">"rows"</span><span class="token operator">:</span> <span
                    class="token punctuation">[</span><span class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"49d4c5f6-a804-41c8-b7e8-21136897c528"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"file_name"</span><span class="token operator">:</span> <span class="token string">"ovaa_1.1.apk"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"version_id"</span><span class="token operator">:</span> <span class="token string">"1.1"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"status"</span><span class="token operator">:</span> <span class="token string">"FINISH"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"version_compare"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
      <span class="token property">"high"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">1</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"medium"</span><span class="token operator">:</span> <span class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">0</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"low"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">0</span>
      <span class="token punctuation">}</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token property">"completed_at"</span><span class="token operator">:</span> <span class="token string">"2020-12-03T22:40:32.662Z"</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span> <span
                    class="token punctuation">{</span>
    <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"32682451-86fe-4020-8cc8-5a90e22ab715"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"file_name"</span><span class="token operator">:</span> <span class="token string">"ovaa_1.0.apk"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"version_id"</span><span class="token operator">:</span> <span class="token string">"1.0"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"status"</span><span class="token operator">:</span> <span class="token string">"FINISH"</span><span
                    class="token punctuation">,</span>
    <span class="token property">"version_compare"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
      <span class="token property">"high"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">0</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"medium"</span><span class="token operator">:</span> <span class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">0</span>
      <span class="token punctuation">}</span><span class="token punctuation">,</span>
      <span class="token property">"low"</span><span class="token operator">:</span> <span
                    class="token punctuation">{</span>
        <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                    class="token number">0</span><span class="token punctuation">,</span>
        <span class="token property">"created"</span><span class="token operator">:</span> <span
                    class="token number">0</span>
      <span class="token punctuation">}</span>
    <span class="token punctuation">}</span><span class="token punctuation">,</span>
    <span class="token property">"completed_at"</span><span class="token operator">:</span> <span class="token string">"2020-01-03T01:37:22.172Z"</span>
  <span class="token punctuation">}</span><span class="token punctuation">]</span>
<span class="token punctuation">}</span>
</code></pre>

            </div>
            <h3 :id="makeAnchor('Adding a new version')"><a
                :href="makeSubLinkToAnchor('Adding a new version', 'Integrations')">Adding a new version</a></h3>
            <p>The number of versions that can be scanned on one Integration is unlimited. But we limit
                the
                number of versions that can be scanned in parallel to 1. To submit another version for
                scanning,
                you need to wait for the current job to finish or else <a
                    :href="makeSubLinkToAnchor('Deleting a version', 'integrations')">delete
                    it</a>.</p>
            <p>If you want to lift this restriction, <a
                href="https://support.oversecured.com/hc/en-us/requests/new">get in touch</a>.</p>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>POST /v1/integrations/{integration_id}/branches/{branch_name}/versions/add</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>integration_id</td>
                    <td>string(uuid)</td>
                    <td>Integration ID, generated automatically when the Integration is created</td>
                </tr>
                <tr>
                    <td>branch_name</td>
                    <td>string</td>
                    <td>Branch name, created by the user. <code>main</code> is the default</td>
                </tr>
                <tr>
                    <td>file_name</td>
                    <td>string</td>
                    <td>file name of the app version</td>
                </tr>
                <tr>
                    <td>bucket_key</td>
                    <td>string</td>
                    <td>key received when the app version file was uploaded.
                        <b-nav-item class="link" :to="{ path: `${makeLinkToAnchor('uploading')}#${makeAnchor('Uploading apps')}`}">View details

                        </b-nav-item>
                    </td>
                </tr>
                <tr>
                    <td>delete_running</td>
                    <td>boolean</td>
                    <td>optional parameter. If <code>true</code>, the current version scan will be stopped
                        and deleted, and
                        the new version will be scanned instead. Default is <code>false</code>
                    </td>
                </tr>
                </tbody>
            </table>
            <p><strong>JSON response</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>id</td>
                    <td>string(uuid)</td>
                    <td>Version ID, generated automatically when the new version is uploaded</td>
                </tr>
                <tr>
                    <td>file_name</td>
                    <td>string</td>
                    <td>file name of the app version uploaded to the platform</td>
                </tr>
                <tr>
                    <td>platform</td>
                    <td>string</td>
                    <td>platform of the scanned version</td>
                </tr>
                <tr>
                    <td>version_id</td>
                    <td>string</td>
                    <td>name of the app version (or null)</td>
                </tr>
                <tr>
                    <td>status</td>
                    <td>string</td>
                    <td>version scan status.
                        <b-nav-item
                            :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Possible scan statuses')}`}">
                            View possible statuses
                        </b-nav-item>
                    </td>
                </tr>
                <tr>
                    <td>version_compare</td>
                    <td>object</td>
                    <td>comparative severity statistics as against the previous version (if any),
                        showing how many vulnerabilities have been created or fixed in each severity group (or null)
                    </td>
                </tr>
                <tr>
                    <td>completed_at</td>
                    <td>string</td>
                    <td>date and time when the scan was completed (or null)</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/add \
  -d '{"file_name": "ovaa_1.2.apk", "bucket_key": "f0fac3d2-5544-4ddd-91c8-15bfd7f087a3/0395ed14-d5ea-4c9e-944d-5d0af425c658/9024d2e3-430e-4126-8445-f2ee0f36b507.apk"}' \
  -H "Content-Type: application/json" -X POST \
    -H 'Authorization: {api_token}'
</code></pre>

            </div>
            <p><strong>Upload steps</strong></p>
            <ol>
                <li>Select an app version file</li>
                <li>
                    <b-nav-item class="link" :to="{ path: `${makeLinkToAnchor('uploading')}#${makeAnchor('Uploading apps')}`}">Submit a request

                    </b-nav-item>
                    to obtain a signed link and bucket key
                </li>
                <li><a
                    href="https://docs.aws.amazon.com/AmazonS3/latest/dev/PresignedUrlUploadObject.html">Upload
                    the file to the URL you receive</a></li>
                <li>Use the <code>bucket_key</code> in this request</li>
            </ol>
            <p><strong>Example response</strong></p>
            <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
                class="token punctuation">{</span>
  <span class="token property">"id"</span><span class="token operator">:</span> <span class="token string">"49d4c5f6-a804-41c8-b7e8-21136897c528"</span><span
                  class="token punctuation">,</span>
  <span class="token property">"file_name"</span><span class="token operator">:</span> <span class="token string">"ovaa_1.1.apk"</span><span
              class="token punctuation">,</span>
  <span class="token property">"platform"</span><span class="token operator">:</span> <span class="token string">"android"</span><span
              class="token punctuation">,</span>
  <span class="token property">"version_id"</span><span class="token operator">:</span> <span class="token">null</span><span
              class="token punctuation">,</span>
  <span class="token property">"status"</span><span class="token operator">:</span> <span class="token string">"CREATED"</span><span
              class="token punctuation">,</span>
  <span class="token property">"version_compare"</span><span class="token operator">:</span> <span class="token">null</span>
  <span class="token property">"completed_at"</span><span class="token operator">:</span> <span class="token">null</span>
<span class="token punctuation">}</span>
  </code></pre>

            </div>
            <h3 :id="makeAnchor('Deleting a version')"><a
                :href="makeSubLinkToAnchor('Deleting a version', 'integrations')">Deleting a version</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>DELETE /v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id}</code></li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                <tr>
                    <th>Name</th>
                    <th>Type</th>
                    <th>Comment</th>
                </tr>
                </thead>
                <tbody>
                <tr>
                    <td>integration_id</td>
                    <td>string(uuid)</td>
                    <td>Integration ID, generated automatically when the Integration is created</td>
                </tr>
                 <tr>
                    <td>branch_name</td>
                    <td>string</td>
                    <td>Branch name, created by the user. <code>main</code> is the default</td>
                </tr>
                <tr>
                    <td>version_id</td>
                    <td>string(uuid)</td>
                    <td>ID of the app version</td>
                </tr>
                </tbody>
            </table>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/{version_id} \
  -X DELETE -H 'Authorization: {api_token}'
</code></pre>

            </div>
          <h3 :id="makeAnchor('Comparison of versions (Statistics)')"><a
              :href="makeSubLinkToAnchor('Comparison of versions (Statistics)', 'integrations')">Comparison of versions (Statistics)</a></h3>
          <p><strong>Endpoint</strong></p>
          <ul>
            <li><code>GET /v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/statistics?old={old_version_id}&amp;new={new_version_id}</code>
            </li>
          </ul>
          <p><strong>Input parameters</strong></p>
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>integration_id</td>
              <td>string(uuid)</td>
              <td>Integration ID, generated automatically when the Integration is created</td>
            </tr>
            <tr>
              <td>branch_name</td>
              <td>string</td>
              <td>Branch name, created by the user. <code>main</code> is the default</td>
            </tr>
            <tr>
              <td>old_version_id</td>
              <td>string(uuid)</td>
              <td>ID of the app version to be compared with the old version</td>
            </tr>
            <tr>
              <td>new_version_id</td>
              <td>string(uuid)</td>
              <td>ID of the app version to be compared</td>
            </tr>
            </tbody>
          </table>
          <p><strong>JSON response</strong></p>
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>high</td>
              <td>object</td>
              <td>Severity statistics showing how many vulnerabilities have been created or fixed
                in the high-severity group
              </td>
            </tr>
            <tr>
              <td>medium</td>
              <td>object</td>
              <td>Severity statistics showing how many vulnerabilities have been created or fixed
                 in the medium-severity group
              </td>
            </tr>
            <tr>
              <td>low</td>
              <td>object</td>
              <td>Severity statistics showing how many vulnerabilities have been created or fixed
                 in the low-severity group
              </td>
            </tr>
            </tbody>
          </table>
          <p><strong>Example response</strong>
          </p>
          <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
              class="token punctuation">{</span>
  <span class="token property">"high"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
    <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                class="token number">1</span><span class="token punctuation">,</span>
    <span class="token property">"created"</span><span class="token operator">:</span> <span
                class="token number">2</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
  <span class="token property">"medium"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
    <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                class="token number">0</span><span class="token punctuation">,</span>
    <span class="token property">"created"</span><span class="token operator">:</span> <span
                class="token number">0</span>
  <span class="token punctuation">}</span><span class="token punctuation">,</span>
  <span class="token property">"low"</span><span class="token operator">:</span> <span
                class="token punctuation">{</span>
    <span class="token property">"fixed"</span><span class="token operator">:</span> <span
                class="token number">0</span><span class="token punctuation">,</span>
    <span class="token property">"created"</span><span class="token operator">:</span> <span
                class="token number">0</span>
  <span class="token punctuation">}</span>
<span class="token punctuation">}</span>
</code></pre>
          </div>
          <p><strong>Using curl</strong></p>
          <div class="code-toolbar"><pre class="  language-metadata"><code
              class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/statistics?old={old_version_id}&amp;new={new_version_id}' \
  -H 'Authorization: {api_token}'
</code></pre>

          </div>
          <h3 :id="makeAnchor('Comparison of versions (Vulnerabilities)')"><a
              :href="makeSubLinkToAnchor('Comparison of versions (Vulnerabilities)', 'integrations')">Comparison of versions (Vulnerabilities)</a></h3>
          <p><strong>Endpoint</strong></p>
          <ul>
            <li><code>GET /v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/vulnerabilities?old={old_version_id}&amp;new={new_version_id}&amp;type={type}&amp;limit={limit}&amp;page={page}</code>
            </li>
          </ul>
          <p><strong>Input parameters</strong></p>
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>integration_id</td>
              <td>string(uuid)</td>
              <td>Integration ID, generated automatically when the Integration is created</td>
            </tr>
            <tr>
              <td>branch_name</td>
              <td>string</td>
              <td>Branch name, created by the user. <code>main</code> is the default</td>
            </tr>
            <tr>
              <td>old_version_id</td>
              <td>string(uuid)</td>
              <td>ID of the app version to be compared with the old version</td>
            </tr>
            <tr>
              <td>new_version_id</td>
              <td>string(uuid)</td>
              <td>ID of the app version to be compared</td>
            </tr>
            <tr>
              <td>type</td>
              <td>string</td>
              <td>Type of vulnerabilities to return after comparing two versions.
                Possible values: <br>
                <code>Created</code> — vulnerabilities that were created in the new version but were not present in the old version;
                and <code>Fixed</code> — vulnerabilities that were present in the older version but have now been fixed in the new version
              </td>
            </tr>
            <tr>
              <td>limit</td>
              <td>integer</td>
              <td>Number of integrations on one page</td>
            </tr>
            <tr>
              <td>page</td>
              <td>integer</td>
              <td>Page number</td>
            </tr>
            </tbody>
          </table>
          <p><strong>JSON response</strong></p>
          <table class="table">
            <thead>
            <tr>
              <th>Name</th>
              <th>Type</th>
              <th>Comment</th>
            </tr>
            </thead>
            <tbody>
            <tr>
              <td>vulnerabilities</td>
              <td>array</td>
              <td>List of vulnerabilities that were created in the new version but were not present in the old version,
                or vulnerabilities that were present in the older version but have now been fixed in the new version
                (depending on the value of the type parameter in the request)
              </td>
            </tr>
            <tr>
              <td>count</td>
              <td>integer</td>
              <td>Number of vulnerabilities
              </td>
            </tr>
            </tbody>
          </table>
          <p><strong>Example response</strong></p>
          <div class="code-toolbar"><pre class="  language-json"><code class="  language-json"><span
              class="token punctuation">{</span>
  <span class="token property">"vulnerabilities"</span><span class="token operator">: </span><span
                class="token punctuation">[</span>
    <span class="token punctuation">{</span>
      <span class="token property">"id"</span><span class="token operator">:</span> <span
                class="token string">"fb3b9867-1590-4a5b-96c2-c91830ac152d"</span><span
                class="token punctuation">,</span>
      <span class="token property">"category_id"</span><span class="token operator">:</span> <span
                class="token string">"cd85663f-49fd-4838-ba72-80c73aa161ca"</span><span
                class="token punctuation">,</span>
      <span class="token property">"hash"</span><span class="token operator">:</span> <span
                class="token string">"b1ffe008b0a4e2e2eb7fae08c9eeafa737ef54fc1a1c69eaea8e0c6ce3212c64"</span><span
                class="token punctuation">,</span>
      <span class="token property">"blocks"</span><span class="token operator">: </span> <span
                class="token punctuation">[</span>
        <span class="token punctuation">{</span>
          <span class="token property">"file"</span><span class="token operator">:</span> <span
                class="token string">"oversecured/ovaa/OversecuredApplication.java"</span><span
                class="token punctuation">,</span>
          <span class="token property">"source_type"</span><span class="token operator">:</span> <span
                class="token string">"java"</span><span class="token punctuation">,</span>
          <span class="token property">"lines"</span><span class="token operator">: </span> <span
                class="token punctuation">[</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">12</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"  }"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">13</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">""</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">14</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"  private void invokePlugins() {"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">15</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"    for (PackageInfo info : getPackageManager().getInstalledPackages(128)) {"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">16</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"      String packageName = info.packageName;"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">17</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"      Bundle meta = info.applicationInfo.metaData;"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">18</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"      if (packageName.startsWith(\"oversecured.plugin.\") &amp;&amp; meta.getInt(\"version\", -1) &gt;= 10) {"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">19</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"        try {"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">true</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">20</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"          createPackageContext(packageName, 3).getClassLoader().loadClass(\"oversecured.plugin.Loader\").getMethod(\"loadMetadata\", Context.class).invoke(null, this);"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">21</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"        } catch (Exception e) {"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">22</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"          throw new RuntimeException(e);"</span>
            <span class="token punctuation">}</span><span class="token punctuation">,</span>
            <span class="token punctuation">{</span>
              <span class="token property">"is_matched"</span><span class="token operator">:</span> <span
                class="token boolean">false</span><span class="token punctuation">,</span>
              <span class="token property">"line_number"</span><span class="token operator">:</span> <span
                class="token number">23</span><span class="token punctuation">,</span>
              <span class="token property">"content"</span><span class="token operator">:</span> <span
                class="token string">"        }"</span>
            <span class="token punctuation">}</span>
          <span class="token punctuation">]</span>
        <span class="token punctuation">}</span>
      <span class="token punctuation">]</span>
    <span class="token punctuation">}</span>
  <span class="token punctuation">]</span><span class="token punctuation">,</span>
  <span class="token property">"count"</span><span class="token operator">:</span> <span class="token number">15</span>
<span class="token punctuation">}</span>
</code></pre>

          </div>
          <p><strong>Using curl</strong></p>
          <div class="code-toolbar"><pre class="  language-metadata"><code
              class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/vulnerabilities?old={old_version_id}&amp;new={new_version_id}&amp;type={type}&amp;limit={limit}&amp;page={page}' \
  -H 'Authorization: {api_token}'
</code></pre>

          </div>
          <h3 :id="makeAnchor('Downloading a PDF of the comparison')"><a
                :href="makeSubLinkToAnchor('Downloading a PDF of the comparison', 'integrations')">Downloading a PDF of the comparison</a></h3>
            <p><strong>Endpoint</strong></p>
            <ul>
                <li><code>GET /v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/download/pdf?old={old_version_id}&amp;new={new_version_id}</code>
                </li>
            </ul>
            <p><strong>Input parameters</strong></p>
            <table class="table">
                <thead>
                    <tr>
                        <th>Name</th>
                        <th>Type</th>
                        <th>Comment</th>
                    </tr>
                </thead>
                <tbody>
                    <tr>
                        <td>integration_id</td>
                        <td>string(uuid)</td>
                        <td>Integration ID, generated automatically when the Integration is created</td>
                    </tr>
                    <tr>
                        <td>branch_name</td>
                        <td>string</td>
                        <td>Branch name, created by the user. <code>main</code> is the default</td>
                    </tr>
                    <tr>
                        <td>old_version_id</td>
                        <td>string(uuid)</td>
                        <td>ID of the app version to be compared with the old version</td>
                    </tr>
                    <tr>
                        <td>new_version_id</td>
                        <td>string(uuid)</td>
                        <td>ID of the app version to be compared</td>
                    </tr>
                </tbody>
            </table>
            <p>The response is in <code>application/pdf</code> format.</p>
            <p><strong>Using curl</strong></p>
            <div class="code-toolbar"><pre class="  language-metadata"><code
                class="  language-metadata">curl 'https://api.oversecured.com/v1/integrations/{integration_id}/branches/{branch_name}/versions/compare/download/pdf?old={old_version_id}&amp;new={new_version_id}' \
  -H 'Authorization: {api_token}' -H 'Accept: application/pdf' \
  --output oversecured_compare_report.pdf
</code></pre>
            </div>
            <h3 :id="makeAnchor('Reading the vulnerability report')"><a
                :href="makeSubLinkToAnchor('Reading the vulnerability report', 'integrations')">Reading the
                vulnerability report</a></h3>
            <p>Operations to work with the report are described in the
                <b-nav-item class="link" :to="{ path: `/docs/api/vulnerabilities`}">
                    corresponding section
                </b-nav-item>
                and include:
            </p>
            <ol>
                <li>
                    <b-nav-item
                        :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('General report statistics')}`}">
                        Reading the overall statistics from the report
                    </b-nav-item>
                </li>
                <li>
                    <b-nav-item
                        :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Listing of vulnerabilities')}`}">
                        Reading the vulnerability listing
                    </b-nav-item>
                </li>
                <li>
                    <b-nav-item
                        :to="{ path: `${makeLinkToAnchor('vulnerabilities')}#${makeAnchor('Moving vulnerabilities between Folders')}`}">
                        Moving vulnerabilities between Folders
                    </b-nav-item>. In Integrations, vulnerabilities moved to some folder will be moved to this folder
                  for all versions where they present, also for the new ones.
                </li>
            </ol>
        </div>
    </div>
</template>

<script>
import {makeAnchor, makeLinkToAnchor, makeSubLinkToAnchor} from "@/services/functions";

export default {
    methods: {
        makeAnchor,
        makeLinkToAnchor,
        makeSubLinkToAnchor
    }
}
</script>

<style scoped>

</style>
