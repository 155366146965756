<template>
    <div class="landing">
        <div>
            <div>
<!--                temporary disable Calendly booking-->
<!--                <a-->
<!--                    class="book-a-call-link"-->
<!--                    href="https://calendly.com/oversecured/30min"-->
<!--                    target="_blank"-->
<!--                    @mousedown="isClicked = !isClicked"-->
<!--                    @mouseup="isClicked = !isClicked"-->
<!--                >-->
<!--                    <span class="book-a-call-icon">-->
<!--                        <CallBookingIcon-->
<!--                            class="book-a-call-svg"-->
<!--                            :clicked="isClicked"-->
<!--                        />-->
<!--                    </span>-->
<!--                    {{ buttonText }}-->
<!--                </a>-->
                <MainTop @hideSpinner="hideSpinner" />
                <MainBrandMustBeProtected />
                <MainRisks />
                <MainMarketLimitations />
                <ReadyToElevateYourSecurity />
                <MainTrulyMatters />
                <MinimizeRisksAutomateDevSecOps />
                <MainEnhanceSecurity />
                <MainAdvancedProtection />
                <MainSuccessStoriesAndHappyCustomers />
                <MainProtectBusinessWithUsVue />
                <MainStayInformedWithBlog />
                <MainFAQs />
                <MainGDPR />
                <!-- <TrialBlock
                    v-if="showMessageIfNoIntegrations"
                    :landing="true"
                    :is-user-auth="isAuthenticated"
                /> -->
            </div>
            <div
                v-if="isLoadingPage"
                class="landing-spinner"
            >
                <Spinner />
            </div>
        </div>
        <div
            v-if="isAuthenticatingNow"
            class="landing-spinner"
        >
            <Spinner />
        </div>
    </div>
</template>
<script>
import MainTop from '../components/main/MainTop/MainTop.vue';
import Spinner from '../components/Spinner';
import MainRisks from '../components/main/MainRisks.vue';
import { mapGetters } from 'vuex';
import CallBookingIcon from '../assets/iconTemplates/CallBookingIcon.vue';
import MainBrandMustBeProtected from '../components/main/YourBrandMustBeProtected/MainBrandMustBeProtected.vue';
import ReadyToElevateYourSecurity from '../components/main/ReadyToElevateYourSecurity.vue';
import MainMarketLimitations from '../components/main/MainMarketLimitations/MainMarketLimitations.vue';
import MinimizeRisksAutomateDevSecOps from '../components/main/MinimizeRisksAutomateDevSecOps/MinimizeRisksAutomateDevSecOps.vue';
import MainAdvancedProtection from '../components/main/MainAdvancedProtection/MainAdvancedProtection.vue';
import MainTrulyMatters from '../components/main/MainTrulyMatters/MainTrulyMatters.vue';
import MainEnhanceSecurity from '../components/main/MainEnhanceSecurity.vue';
import MainProtectBusinessWithUsVue from '../components/main/MainProtectBusinessWithUs/MainProtectBusinessWithUs.vue';
import MainSuccessStoriesAndHappyCustomers from '../components/main/MainSuccessStoriesAndHappyCustomers/MainSuccessStoriesAndHappyCustomers.vue';
import MainFAQs from '../components/main/mainFAQs/mainFAQs.vue';
import MainStayInformedWithBlog from '../components/main/MainStayInformedWithBlog/MainStayInformedWithBlog.vue';
import MainGDPR from '../components/main/MainGDPR.vue';

export default {
    components: {
        MainTop,
        MainRisks,
        Spinner,
        CallBookingIcon,
        MainBrandMustBeProtected,
        ReadyToElevateYourSecurity,
        MainMarketLimitations,
        MainTrulyMatters,
        MainEnhanceSecurity,
        MinimizeRisksAutomateDevSecOps,
        MainSuccessStoriesAndHappyCustomers,
        MainAdvancedProtection,
        MainProtectBusinessWithUsVue,
        MainFAQs,
        MainStayInformedWithBlog,
        MainGDPR,
    },
    data() {
        return {
            isClicked: false,
            buttonText: 'Book a Call',
            isLoadingPage: true,
            showMessageIfNoIntegrations: 0,
        };
    },
    computed: {
        isAuthenticated: {
            get() {
                return this.$auth.isAuthenticated;
            },
        },
        ...mapGetters('user', {
            isAuthenticatingNow: 'isAuthenticatingNow',
            trial: 'getTrial',
        }),
    },
    beforeMount() {
        this.showMessageIfNoIntegrations = +localStorage.getItem('integrationsCount') === 0;
    },
    methods: {
        hideSpinner() {
            if (document.readyState === 'complete') {
                this.isLoadingPage = false;
            } else {
                document.onreadystatechange = () => {
                    if (document.readyState === 'complete') {
                        this.isLoadingPage = false;
                    }
                };
            }

        },
    },
};
</script>
<style lang="scss">
@import "../assets/css/variables";
@import "../assets/css/fonts";

    .landing  {
        position: relative;
        overflow: hidden;
    }

    .divider {
        color: #BEBEBE;
        border-width: 2px;
    }

    .full-height {
        height: 100%;
        position: relative;
    }

    .landing-spinner {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: white;
        z-index: 10;
    }

    .book-a-call-link {
        @include Aspekta-font(650, 16px, normal, #FFFFFF);
        display: flex;
        justify-content: flex-start;
        align-items: center;
        gap: 1.1rem;
        width: 12.5rem;
        height: 4.25rem;
        padding: 1.25rem 0rem 1.25rem 2rem;
        background: $new-orange-color;
        position: fixed;
        top: 138px;
        z-index: 999;
        right: -130px;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        text-decoration: none !important;
        transition: right 200ms linear, transform 200ms linear, background-color 200ms linear, transform 200ms linear;

        &:hover{
            right: -2px;
            color: white;
        }

        &:active {
            background-color: #FFFFFF;
            border: 2px solid #E8E7EF;
            color: #0C0D0E;
        }
    }
    .book-a-call-icon {
        display: inline-block;
    }

    @media (max-width: 768px) {

        .book-a-call-link {
            padding: 1.12rem 0rem 1.12rem 1.25rem;
            height: 3.5rem;
            right: -155px;
        }

        .book-a-call-svg{
            width: 22px;
            height: 22px;
        }
    }
</style>
