<template>
    <div class="inputs__wrapper">
        <div
            class="input-wrapper"
            :class="{ 'input-error': inputErrorData.name?.length > 0 }"
        >
            <label
                for="fname"
            >Contact Person <span class="orange-star">*</span></label>
            <input
                id="name"
                v-model.trim="inputData.name"
                class="tex"
                type="text"
                name="name"
                placeholder="Name"
                @input="handleInput('name')"
            >
            <img
                v-if="inputData.name?.length > 0"
                src="../../assets/images/cross_grey.svg"
                alt="cross"
                @click="clearInput('name')"
            >
            <span
                v-if="inputData.name?.length > 0"
                class="error-text"
            >{{ inputErrorData.name }}</span>
        </div>
        <div
            class="input-wrapper"
            :class="{ 'input-error': inputErrorData.jobTitle?.length > 0 }"
        >
            <label for="lname">Job Title <span class="orange-star">*</span></label>
            <input
                id="jobTitle"
                v-model.trim="inputData.jobTitle"
                type="text"
                name="jobTitle"
                placeholder="Job Title"
                @input="handleInput('jobTitle')"
            >
            <img
                v-if="inputData.jobTitle?.length > 0"
                src="../../assets/images/cross_grey.svg"
                alt="cross"
                @click="clearInput('jobTitle')"
            >
            <span
                v-if="inputData.jobTitle?.length > 0"
                class="error-text"
            >{{ inputErrorData.jobTitle }}</span>
        </div>
        <div
            class="input-wrapper"
            :class="{ 'input-error': inputErrorData.email?.length > 0 }"
        >
            <label for="lname">Corporate Email Address <span class="orange-star">*</span></label>
            <input
                id="email-address"
                v-model.trim="inputData.email"
                type="text"
                name="email"
                placeholder="Corporate Email"
                @input="handleInput('email')"
            >
            <img
                v-if="inputData.email?.length > 0"
                src="../../assets/images/cross_grey.svg"
                alt="cross"
                @click="clearInput('email')"
            >
            <span
                v-if="inputData.email?.length > 0"
                class="error-text"
            >{{ inputErrorData.email }}</span>
        </div>
    </div>
</template>
<script>
import {
    validateEmail,
    checkFieldCompleteness,
    checkFormInputsForCompleteness,
    isGmail
} from '../../services/functions';

export default {
    props: {
        stepData: Object,
    },
    data() {
        return {
            inputData: {
                name: this.stepData ? this.stepData.name : '',
                jobTitle:  this.stepData ? this.stepData.jobTitle : '',
                email:  this.stepData ? this.stepData.email : '',
            },
            inputErrorData: {
                name: '',
                jobTitle: '',
                email: '',
            },
        };
    },

    methods: {
        clearInput(inputField) {
            this.inputData[inputField] = '';
            this.inputErrorData[inputField] = '';
            this.$emit('updateInputData', { ...this.inputData });
            this.$emit('updateIsDataComplete', this.isComplete());
            this.$emit('updateIsDataValid', this.validateInput(inputField));
        },
        handleInput(inputField) {
            this.inputErrorData[inputField] = '';
            this.$emit('updateInputData', { ...this.inputData });
            this.$emit('updateIsDataComplete', this.isComplete());
            this.$emit('updateIsDataValid', this.validateInput(inputField));
        },
        isComplete() {
            return checkFormInputsForCompleteness(this.inputData);
        },
        validateInput(inputField) {
            if (!checkFieldCompleteness(this.inputData[inputField])) {
                this.inputErrorData[inputField] = 'Max input length 1000';
            }
            if (inputField === 'email' && this.inputData.email.length > 0) {
                const isEmailValid = validateEmail(this.inputData.email) && !isGmail(this.inputData.email);
                if (!isEmailValid) {
                    this.inputErrorData.email = 'Please enter a valid email address';
                }
                return isEmailValid && this.isComplete();
            } else {
                return this.isComplete()
            }
        },
    },
};
</script>

<style lang="scss" scoped>
@import "../../assets/css/variables/variablesForRequestDemo";
@import "../../assets/css/fonts";

    .inputs__wrapper {
        display: flex;
        flex-direction: column;
        gap: 2.5rem;
        width: 100%;
    }

    .input-wrapper {
        display: flex;
        flex-direction: column;
        position: relative;
        width: 100%;
        gap: 8px;
    }

    .input-wrapper label {
        @include Aspekta-font(400, 14px, 140%, #797E86);
        letter-spacing: 0em;
    }

    .input-wrapper input:focus-visible {
        outline: none;
        border: 1px solid #3180F6;
    }

    .input-error input:focus-visible {
        border: 1px solid #FE2027;
    }

    .input-wrapper img {
        height: 11px;
        width: 11px;
        position: absolute;
        bottom: 20px;
        right: 21px;
        cursor: pointer;
    }

    .input-wrapper {
        &:focus-within {
            label {
                color: #3180F6;
            }
        }
    }

    .input-error {
        &:focus-within {
            label {
                color: #FE2027;
            }
        }
    }

    .input-wrapper input {
        @include Aspekta-font(450, $dynamic-demo-input-placeholder-font-size, 150%, #0C0D0E);
        letter-spacing: 0em;
        border: 1px solid #E8E7EF;
        padding: 12px 39px 12px 24px;
        border-radius: 10px;
        text-overflow: ellipsis;

            &::placeholder {
                color: #797E86 !important;
            }
    }

    .orange-star {
        @include Aspekta-font(700, 14px, 140%, #FF7A08);
    }

    .error-text {
        @include Aspekta-font(400, 12px, 150%, #FE2027);
        position: absolute;
        bottom: -25px;
    }

    @media (max-width: 1000px) {
        .input-wrapper input {
            padding: 18px 35px 18px 16px;
        }

        .input-wrapper img {
            bottom: 25px;
        }
    }
</style>
